import GetItOnPlayStoreImage from "../../assets/images/getItOnPlayStoreImage";
import FooterTextHeading from "../../components/footerTextHeadings";
import "./css/letsTryItOut.css";
function LetsTryItOut() {
  return (
    <div className="letTryItOut">
      <FooterTextHeading heading="Trigsy Partners" />
      {/* <section id="footerDownloadAppStoreImage">
        <DownloadAppStoreImage />
        <p id="comingSoonText">Coming Soon</p> */}
      {/* </section> */}
      <section id="footerDownloadAppStoreImage">
        <a
          href="https://play.google.com/store/apps/details?id=com.trigsy.partner&hl=en-IN"
          target="_blank"
          rel="noreferrer"
        >
          <GetItOnPlayStoreImage />
        </a>
      </section>
    </div>
  );
}
export default LetsTryItOut;
