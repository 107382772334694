import "./css/partnerFooter.css";
import HelpAndSupport from "./helpAndSupport";
import LetsTryItOut from "./letsTryItOut";
import TrigsySection from "./trigsySection";
import UsefulLinks from "./usefulLinks";
function Footer() {
  return (
    <div>
      <div className="partnerFooter">
        <div className="partnerFooterContent">
          <TrigsySection />
          <UsefulLinks />
          <HelpAndSupport />
          <LetsTryItOut />
        </div>
      </div>
      <section id="partnercopyRight">
        <p id="partnerCopyRightText">© 2023 Trigsy Technologies Pvt. Ltd.</p>
      </section>
    </div>
  );
}
export default Footer;
