import "../components/css/imageWithHeadingAndSubHeading.css";
function ImageWithHeadingAndSubHeading(props) {
  const { heading, subHeading, imagePath } = props;
  return (
    <div className="ImageWithHeadingAndSubHeading">
      <div className="imageLogo">
        <img src={imagePath} alt="shop" />
      </div>
      <div className="texts">
        <p id="commonHeading">{heading}</p>
        <p id="commonSubHeading">{subHeading}</p>
      </div>
    </div>
  );
}

export default ImageWithHeadingAndSubHeading;
