import { React } from "react";
import "../header/css/navbar.css";
import Home from "../main/home/home";
import About from "../main/about/about";
import HowItWorks from "../main/howItWorks/howItWorks";
import DownloadOurApp from "../main/downloadOurApp/customerDownloadOurApp";
import ContactUs from "../main/contactUs/contactUs";
import { useState } from "react";

function Body() {
  const [message, setMessage] = useState(false);
  const chooseMessage = (message) => {
    setMessage(message);
    console.log("Inside function", message);
  };
  console.log("inside parent: ", message);
  return (
    <>
      {message && (
        <div className="customer-loader-container">
          <div className="customer-spinner"></div>
        </div>
      )}
      <div className="NavbarItems">
        <section id="home">
          <Home />
        </section>
        <section id="customerYoutubeVideo">
          <iframe
            width="1200"
            height="675"
            src={`https://www.youtube.com/embed/gdmRQFDWH6U?autoplay=1&loop=1&playlist=gdmRQFDWH6U&mute=1`}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        </section>
        <section id="about">
          <About />
        </section>
        <section id="howItWorks">
          <HowItWorks />
        </section>
        <section id="download">
          <DownloadOurApp />
        </section>

        <section id="contact">
          <ContactUs chooseMessage={chooseMessage} />
        </section>
      </div>
    </>
  );
}

export default Body;
