import "../../main/home/css/homeContent.css";
import GetItOnPlayStoreImage from "../../../assets/images/getItOnPlayStoreImage";
import DownloadAppStoreImage from "../../../assets/images/downloadAppStoreImage";

function HomeContent() {
  return (
    <div className="homeContent">
      <div className="homeBody">
        <p id="heading">Cloud Shop</p>
        <p id="subHeading">A virtual space for all your needs</p>
        <p id="content">
          Welcome to our virtual space, a diverse online platform where
          individuals from all walks of life can come together to connect,
          collaborate, and create. Experience a boundless virtual realm that
          transcends physical barriers, fostering inclusivity, and empowering
          users to unleash their imagination.
        </p>
        <span id="appStoreImage">
          <a
            href="https://apps.apple.com/in/app/trigsy/id6452193884"
            target="_blank"
            rel="noreferrer"
          >
            <DownloadAppStoreImage />
          </a>
        </span>
        <span id="playStoreImage">
          <a
            href="https://play.google.com/store/apps/details?id=com.trigsy.customer&hl=en-IN"
            target="_blank"
            rel="noreferrer"
          >
            <GetItOnPlayStoreImage />
          </a>
        </span>
      </div>
      <div id="rightSideIcon">
        <img
          src={require("../../../assets/images/homeRightSideImage.svg").default}
          alt="img"
        />
      </div>
    </div>
  );
}

export default HomeContent;
