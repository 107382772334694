import "./css/footerTextHeading.css";
function FooterTextHeading(params) {
  const { heading } = params;
  return (
    <div>
      <p id="footerTextHeading">{heading}</p>
    </div>
  );
}
export default FooterTextHeading;
