import DownloadAppStoreImage from "../../../customers/assets/images/downloadAppStoreImage";
import GetItOnPlayStoreImage from "../../assets/images/getItOnPlayStoreImage";
import FooterTextHeading from "../../components/footerTextHeadings";
import "./css/letsTryItOut.css";
function LetsTryItOut() {
  return (
    <div className="partnerLetTryItOut">
      <FooterTextHeading heading="Trigsy Customers" />
      {/* <section id="footerDownloadAppStoreImage">
        <DownloadAppStoreImage />
        <p id="comingSoonText">Coming Soon</p> */}
      {/* </section> */}
      <section id="partnerFooterDownloadAppStoreImage">
        <a
          href="https://play.google.com/store/apps/details?id=com.trigsy.customer&hl=en-IN"
          target="_blank"
          rel="noreferrer"
        >
          <div id="partnerFooterPlayStoreImage">
            <GetItOnPlayStoreImage />
          </div>
        </a>
        <span id="partnerFooterAppStoreImage">
          <a
            href="https://apps.apple.com/in/app/trigsy/id6452193884"
            target="_blank"
            rel="noreferrer"
          >
            <DownloadAppStoreImage />
          </a>
        </span>
      </section>
    </div>
  );
}
export default LetsTryItOut;
