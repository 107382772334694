import { React } from "react";
import "../header/css/partnerNavbar.css";
import Home from "../main/home/home";
import About from "../main/about/about";
import HowItWorks from "../main/howItWorks/howItWorks";
import DownloadOurApp from "../main/downloadOurApp/downloadOurApp";
import ContactUs from "../main/contactUs/contactUs";
import { useState } from "react";
function PartnerBody() {
  const [message, setMessage] = useState(false);
  const chooseMessage = (message) => {
    setMessage(message);
    console.log("Inside function", message);
  };
  console.log("inside parent: ", message);
  return (
    <>
      {message && (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      )}
      <div className="NavbarItems">
        <section id="home">
          <Home />
        </section>
        <section id="partnerYoutubeVideo">
          <iframe
            width="1200"
            height="675"
            src={`https://www.youtube.com/embed/D1h2QJrMA_4?autoplay=1&loop=1&playlist=D1h2QJrMA_4&mute=1`}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        </section>
        <section id="about">
          <About />
        </section>
        <section id="howItWorks">
          <HowItWorks />
        </section>
        <section id="download">
          <DownloadOurApp />
        </section>

        <section id="contact">
          <ContactUs chooseMessage={chooseMessage} />
        </section>
      </div>
      )
    </>
  );
}

export default PartnerBody;
