import "./css/iconWithHeadingAndSubHeading.css";
function IconWithHeadingAndSubHeading(props) {
  const { heading, subHeading, imagePath } = props;
  return (
    <div className="iconWithHeadingAndSubHeading">
      <div className="iconLogo">
        <img src={imagePath} alt="shop" />
      </div>
      <div className="iconTexts">
        <p id="iconHeading">{heading}</p>

        <p id="iconSubHeading">{subHeading}</p>
      </div>
    </div>
  );
}

export default IconWithHeadingAndSubHeading;
