import "./css/usefulLinkText.css";
function UsefulLinkTexts(params) {
  const { text } = params;
  return (
    <div>
      <p id="usefulLinkText">{text}</p>
    </div>
  );
}
export default UsefulLinkTexts;
