import "./css/iconWithLinkSubHeading.css";
function IconWithLinkSubHeading(params) {
  const { subHeading, icon, linkPath } = params;
  return (
    <div className="iconWithSubheading">
      <span id="footerHelpSupportIcon">
        <img src={icon} alt="icon" />
      </span>
      <a id="footerHelpSupportText" href={linkPath}>
        <p>{subHeading}</p>
      </a>
    </div>
  );
}
export default IconWithLinkSubHeading;
