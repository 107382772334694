import FooterTextHeading from "../../components/footerTextHeadings";
import IconWithLinkSubHeading from "../../components/iconWithLinkSubHeading";
import "./css/partnerHelpAndSupport.css";
function HelpAndSupport() {
  return (
    <div className="partnerHelpAndSupport">
      {" "}
      <div id="partnerHelpSupportHeading">
        <FooterTextHeading heading="Help & Support" />
      </div>
      <IconWithLinkSubHeading
        linkPath="mailto:partner-support@trigsy.com?body="
        subHeading="partner-support@trigsy.com"
        icon={require("../../assets/images/footer/footerEmail.svg").default}
      />
    </div>
  );
}
export default HelpAndSupport;
