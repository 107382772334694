import React, { useState } from "react";
import "./css/textField.css";
function TextField({ loading }) {
  const [saledPartnerId, setSalesPartnerId] = useState("");
  const [plan, setPlan] = useState("");
  const [shopId, setShopId] = useState("");
  const handleChange = (event) => {
    setSalesPartnerId(event.target.value);
  };

  const handlePlan = (event) => {
    setPlan(event.target.value);
  };
  const handleShopId = (event) => {
    setShopId(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      saledPartnerId.trim() === "" ||
      plan.trim() === "" ||
      shopId.trim() === ""
    ) {
      alert("Please fill the details");
    } else {
      console.log("Sales Partner Id :: " + saledPartnerId);
      console.log("Plan Id :: " + plan);
      loading(true);
      console.log("Shop Id :: " + shopId);
      try {
        fetch("https://api.trigsy.com/v1/partners/recharge", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            shopId: shopId,
            rechargePlanId: plan,
            spId: saledPartnerId,
          }),
        })
          .then((response) => {
            if (!response.ok) throw new Error(response.statusText);
            console.log(response);
          })
          .then((data) => {
            loading(false);
            console.log("Response:", data);
            console.log("Submitted");
            alert("You will recieve an email with payment link shortly!");
          })
          .catch((error, sT) => {
            alert("Something Went Wrong! Please try again.");
            loading(false);
            console.error("Error:", error);
            console.error("StackTrace:", sT);
            // Handle the error condition
          });
      } catch (e) {
        console.log("Error in try catch");
      }
    }
  };

  return (
    <div>
      <div className="textFormFieldContainer">
        {
          <div className="insideTextFormFieldContainer">
            <form onSubmit={handleSubmit}>
              <label id="salesPartnerId">Sales Partner Id :</label>
              <input
                input
                type="text"
                id="textField"
                name="salesPartnerId"
                placeholder="SPID"
                value={saledPartnerId}
                onChange={handleChange}
              />

              <label id="salesPartnerId">Plan :</label>
              <div class="select-wrapper">
                <select
                  id="dropdownList"
                  name="PlanDetails"
                  onChange={handlePlan}
                >
                  <option value="">Select an option</option>
                  <option value="RCH-2">Plan-200</option>
                  <option value="RCH-3">Plan-400</option>
                  <option value="RCH-4">Plan-500</option>
                </select>
              </div>
              <label id="salesPartnerId">Shop Id :</label>
              <input
                id="textField"
                value={shopId}
                name="shopId"
                onChange={handleShopId}
                placeholder="Shop Id"
              ></input>
              <p onClick={handleSubmit} id="submitBtn">
                Recharge
              </p>
            </form>
          </div>
        }
      </div>
    </div>
  );
}

export default TextField;
