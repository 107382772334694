import IconWithHeadingAndSubHeading from "../../../components/IconWithHeadingAndSubHeading";
import CustomerIconWithHeadingAndSubHeading from "../../../components/customerIconWithHeadingAndLinkSubheading";
import "./css/contactUs.css";
import TextFieldsFiles from "./textFormField";
function ContactUs({ chooseMessage }) {
  return (
    <div className="contactUs">
      <div>
        <p id="contactUsHeading">Contact Us</p>
      </div>
      <div className="contactUsBody">
        <div className="contactUsTextField">
          <span className="sendUsAMessage">
            <p id="sendUsText">Send us a </p>
            <p id="messageText">message</p>
          </span>
          <p id="textFieldSubHeading">
            Fill up below form , our team will get u soon
          </p>
          <TextFieldsFiles chooseMessage={chooseMessage} />
        </div>
        <div className="contactUsTexts">
          <IconWithHeadingAndSubHeading
            heading="Have any Questions ?"
            subHeading="Got Questions? Reach out to us, and our dedicated team will be delighted to assist you with any inquiries or concerns you may have."
            imagePath={
              require("../../../assets/images/contactUs/chat.svg").default
            }
          />
          <div id="emailUsText">
            <CustomerIconWithHeadingAndSubHeading
              heading="Email us"
              linkPath="mailto:customer-support@trigsy.com?body="
              subHeading="customer-support@trigsy.com"
              imagePath={
                require("../../../assets/images/contactUs/email.svg").default
              }
            />
          </div>
          {/* <IconWithHeadingAndSubHeading
            heading="Contact"
            subHeading="01414937621,100#"
            imagePath={
              require("../../../assets/images/contactUs/call.svg").default
            }
          /> */}
        </div>
      </div>
    </div>
  );
}
export default ContactUs;
