import "./css/iconWithHeadingAndSubHeading.css";
function IconWithHeadingAndSubHeading(props) {
  const { heading, subHeading, imagePath, linkPath } = props;
  return (
    <div className="iconWithHeadingAndSubHeading">
      <div className="iconLogo">
        <img src={imagePath} alt="shop" />
      </div>
      <div className="iconTexts">
        <p id="iconHeading">{heading}</p>
        <a href={linkPath}>
          {" "}
          <p id="iconSubHeading">{subHeading}</p>
        </a>
      </div>
    </div>
  );
}

export default IconWithHeadingAndSubHeading;
