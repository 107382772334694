import FooterTextHeading from "../../components/footerTextHeadings";
import UsefulLinkTexts from "../../components/usefulLinksTexts";
import "./css/usefulLinks.css";
import { Link } from "react-scroll";
function UsefulLinks() {
  return (
    <div className="partnerUsefulLinks">
      <FooterTextHeading heading="Useful Links" />
      <Link
        spy={true}
        smooth={true}
        activeClass="active"
        to="howItWorks"
        id="partnerHowItWorksFooter"
      >
        {" "}
        <UsefulLinkTexts text="How it works" />{" "}
      </Link>
      <a
        href="/legal/customer-terms.html"
        target="_blank"
        rel="noreferrer"
        id="partnerFooterLinks"
      >
        <UsefulLinkTexts text="Customer Terms" />{" "}
      </a>
      <a
        href="/legal/partner-terms.html"
        target="_blank"
        rel="noreferrer"
        id="partnerFooterLinks"
      >
        <UsefulLinkTexts text="Partner Terms" />{" "}
      </a>
      <a
        href="/legal/privacy-policy.html"
        target="_blank"
        rel="noreferrer"
        id="partnerFooterLinks"
      >
        {" "}
        <UsefulLinkTexts text="Privacy policy" />{" "}
      </a>
    </div>
  );
}
export default UsefulLinks;
