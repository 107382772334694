import "./css/verticalDottedLines.css";

const VerticalDottedLine = () => {
  return (
    <div className="line-container">
      <div className="vertical-line" />
    </div>
  );
};

export default VerticalDottedLine;
