import "./css/customerIconWithLinkSubHeading.css";
function CustomerIconWithHeadingAndSubHeading(props) {
  const { heading, subHeading, imagePath, linkPath } = props;
  return (
    <div className="customerIconWithHeadingAndSubHeading">
      <div className="customerIconLogo">
        <img src={imagePath} alt="shop" />
      </div>
      <div className="customerIconTexts">
        <p id="customerIconHeading">{heading}</p>
        <a id="customerIconSubHeading" href={linkPath}>
          {" "}
          <p>{subHeading}</p>
        </a>
      </div>
    </div>
  );
}

export default CustomerIconWithHeadingAndSubHeading;
