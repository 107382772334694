import "./App.css";
import Footer from "../src/customers/layouts/footer/footer";
import Body from "../src/customers/layouts/header/navbar";
import NavbarItems from "../src/customers/layouts/header/navbarItems";

function App() {
  return (
    <div className="App">
      <header>
        <NavbarItems />
      </header>
      <body>
        <Body />
      </body>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default App;
