import "./partnerApp.css";
import PartnerNavbarItems from "./layouts/header/partnerNavbarItems.js";
import PartnerBody from "./layouts/header/partnerNavbar";
import Footer from "../partners/layouts/footer/footer";
function PartnerApp() {
  return (
    <div className="App">
      <header>
        {/* <NavbarItems /> */}
        <PartnerNavbarItems />
      </header>
      <body>
        <PartnerBody />
      </body>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default PartnerApp;
