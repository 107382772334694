import { React, useState } from "react";
import "../../layouts/header/css/navbarItems.css";
import { Link } from "react-scroll";
import Logo from "../../assets/images/logo";
import { Link as DOMLink } from "react-router-dom";
import Hamburger from "../../assets/images/hambuger";
const NavbarItems = () => {
  const [showNavbar, setShowNavbar] = useState(false);

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  return (
    <nav className="navbar">
      <div className="container">
        <div className="logo">
          <Logo />
          <p id="trigsy-title">Trigsy</p>
        </div>
        <div className="menu-icon" onClick={handleShowNavbar}>
          <Hamburger />
        </div>
        <div className={`nav-elements  ${showNavbar && "active"}`}>
          <ul>
            <li>
              <Link
                spy={true}
                smooth={true}
                activeClass="active"
                default
                to="home"
                onClick={handleShowNavbar}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                spy={true}
                smooth={true}
                duration={500}
                to="about"
                onClick={handleShowNavbar}
              >
                About
              </Link>
            </li>
            <li id="howItWorksId">
              <Link
                activeClass="active"
                to="howItWorks"
                spy={true}
                smooth={true}
                duration={500}
                onClick={handleShowNavbar}
              >
                How it works
              </Link>
            </li>

            <li>
              <Link
                activeClass="active"
                spy={true}
                smooth={true}
                duration={500}
                to="contact"
                onClick={handleShowNavbar}
              >
                Contact Us
              </Link>
            </li>
            <li id="partners">
              <DOMLink to="/partners" target="_blank">
                Partners
              </DOMLink>
            </li>
            <li>
              <Link
                activeClass="downloadActive"
                spy={true}
                smooth={true}
                duration={500}
                to="download"
                onClick={handleShowNavbar}
              >
                <p id="downloadBtn">Download</p>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavbarItems;
