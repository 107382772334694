import "../../main/home/css/homeContent.css";
import GetItOnPlayStoreImage from "../../../assets/images/getItOnPlayStoreImage";

function HomeContent() {
  return (
    <div className="homeContent">
      <div className="homeBody">
        <p id="heading">A virtual space for everyone to be a brand</p>
        <p id="content">
          Everyone is unique, and we want to show your uniqueness to the world.
          A platform where people with various skillsets would be creating their
          own brand while serving the rest of the world with their craftsmanship
          & creativity.
        </p>

        <span id="partnerPlayStoreImage">
          <a
            href="https://play.google.com/store/apps/details?id=com.trigsy.partner&hl=en-IN"
            target="_blank"
            rel="noreferrer"
          >
            <GetItOnPlayStoreImage />
          </a>
        </span>
      </div>
      <div id="rightSideIcon">
        <img
          src={
            require("../../../assets/images/partner-dashboard-herosection.svg")
              .default
          }
          alt="img"
        />
      </div>
    </div>
  );
}

export default HomeContent;
