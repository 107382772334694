import HeadingAndSubHeadingWithCircleNumber from "../../../components/headingAndSubHeadingWithCircleNumber";
import "./css/downloadStepTwo.css";
function DownloadStepTwo() {
  return (
    <div>
      <div className="downloadStep2">
        <div id="downloadImage2">
          <img
            src={require("../../../assets/images/howItWorks/downloadStep2.svg").default}
            alt="Download_image_2"
          />
        </div>
        <div id="createAccount">
          <HeadingAndSubHeadingWithCircleNumber
            heading="Create Account"
            subHeading="Sign up for account"
            stepNumberRight="2"
          />
        </div>
      </div>
    </div>
  );
}
export default DownloadStepTwo;
