import ImageWithHeadingAndSubHeading from "../../../components/imageWithHeadingAndSubHeading";

import "../about/css/about.css";
function About() {
  return (
    <div className="About">
      <p id="aboutHeading">
        {" "}
        Power Your Business Growth With Our Innovative Solution
      </p>
      <div className="aboutBody">
        <section id="cloudShopText1">
          <ImageWithHeadingAndSubHeading
            heading="Cloud Shop"
            subHeading="Seamlessly scale your business, reduce infrastructure costs, and gain flexible access to a range of powerful e-commerce tools and services."
            imagePath={
              require("../../../assets/images/aboutApp/shop.svg").default
            }
          />
        </section>
        <section id="onlineMarkerPlace">
          <ImageWithHeadingAndSubHeading
            heading="Online Marketplace"
            subHeading="Empowering professionals to thrive and flourish through an innovative online marketplace for competition and growth."
            imagePath={
              require("../../../assets/images/aboutApp/settingsIcon.svg")
                .default
            }
          />
        </section>
        <section id="scaleTheBussiness">
          <ImageWithHeadingAndSubHeading
            heading="Scale the business"
            subHeading="Expand the team's composition and strategically deploy personnel across multiple regions or cities."
            imagePath={
              require("../../../assets/images/aboutApp/peopleIcon.svg").default
            }
          />
        </section>
        <section id="workFromAnyWhere">
          <ImageWithHeadingAndSubHeading
            heading="Work from anywhere"
            subHeading="Wherever you go, the customer can follow you, your achievements & your shop."
            imagePath={
              require("../../../assets/images/aboutApp/locationIcon.svg")
                .default
            }
          />
        </section>
        <section id="aboutImage">
          <img
            src={
              require("../../../assets/images/aboutApp/aboutApp.svg").default
            }
            alt="AboutImage"
          />
        </section>
      </div>
    </div>
  );
}
export default About;
