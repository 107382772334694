import React, { useState } from "react";
import "./css/textFormField.css";
function TextField({ chooseMessage }) {
  const [nameText, setName] = useState("");
  const [emailName, setEmail] = useState("");
  const [yourMsg, setYourMsg] = useState("");

  const handleChange = (event) => {
    setName(event.target.value);
  };
  const handleEmail = (event) => {
    setEmail(event.target.value);
  };
  const handleMessage = (event) => {
    setYourMsg(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      nameText.trim() === "" ||
      emailName.trim() === "" ||
      yourMsg.trim() === ""
    ) {
      console.log("tapped");
      alert("Please fill the details");
    } else {
      console.log("Name is :: " + nameText);
      console.log("Email is  ::" + emailName);
      console.log("Your Message :: " + yourMsg);

      console.log(
        "request body :: ",
        JSON.stringify({
          email: emailName,
          msg: yourMsg,
          name: nameText,
        })
      );
      chooseMessage(true);
      fetch("https://api.trigsy.com/v1/users/contact-us", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: emailName,
          msg: yourMsg,
          name: nameText,
        }),
      })
        .then((response) => console.log(response))
        .then((data) => {
          // console.log("Response:", data);
          chooseMessage(false);
          console.log("Submitted");
          setName("");
          setEmail("");
          setYourMsg("");
          alert(
            "Thank you for reaching out to us! Your message has been successfully sent. We will get back to you shortly"
          );
          // Perform any additional actions with the response data
        })
        .catch((error, sT) => {
          chooseMessage(false);
          setName("");
          setEmail("");
          setYourMsg("");
          console.error("Error:", error);
          console.error("StackTrace:", sT);
          alert(
            "Thank you for reaching out to us! Your message has been successfully sent. We will get back to you shortly"
          );
          // Handle the error condition
        });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        input
        type="text"
        id="nameTextField"
        name="firstname"
        placeholder="Name"
        value={nameText}
        onChange={handleChange}
      />
      <input
        input
        type="email"
        id="emailTextField"
        name="email"
        placeholder="Email"
        value={emailName}
        onChange={handleEmail}
      />
      <textarea
        id="yourMessage"
        value={yourMsg}
        onChange={handleMessage}
        placeholder="Your Message"
      ></textarea>
      <p onClick={handleSubmit} id="submitBtn">
        Send Message
      </p>
    </form>
  );
}

export default TextField;
