import "./css/partnerIconWithHeadingAndLinkSubHeading.css";
function PartnerIconWithHeadingAndSubHeading(props) {
  const { heading, subHeading, imagePath, linkPath } = props;
  return (
    <div className="partnerIconWithHeadingAndSubHeading">
      <div className="partnerIconLogo">
        <img src={imagePath} alt="shop" />
      </div>
      <div className="partnerIconTexts">
        <p id="partnerIconHeading">{heading}</p>
        <a id="partnerIconSubHeading" href={linkPath}>
          {" "}
          <p>{subHeading}</p>
        </a>
      </div>
    </div>
  );
}

export default PartnerIconWithHeadingAndSubHeading;
