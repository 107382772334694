import "./css/customerDownloadOurApp.css";
import DownloadAppStoreImage from "../../../assets/images/downloadAppStoreImage";
import GetItOnPlayStoreImage from "../../../assets/images/getItOnPlayStoreImage";

function DownloadOurApp() {
  return (
    <div className="cusomterdownloadAppContainer  ">
      <div className="customerdownloadTextContents">
        <p id="customerdownloadAppHeading">Download Our App</p>
        <span id="customerdownloadAppSubHeading">
          <p>
            Enhance your virtual journey, download our app from the Play Store
            or App Store and step into a world of boundless possibilities.
          </p>
        </span>
        <div className="customerdownloadAppImages">
          <span id="customerdownloadAppStoreImage">
            <a
              href="https://apps.apple.com/in/app/trigsy/id6452193884"
              target="_blank"
              rel="noreferrer"
            >
              <DownloadAppStoreImage />
            </a>
          </span>
          <span id="downloadPlayStoreImage">
            <a
              href="https://play.google.com/store/apps/details?id=com.trigsy.customer&hl=en-IN"
              target="_blank"
              rel="noreferrer"
            >
              <GetItOnPlayStoreImage />
            </a>
          </span>
        </div>
      </div>

      <div className="customerdownloadAppImage">
        <img
          src={
            require("../../../assets/images/downloadOurApp/downloadApp.svg")
              .default
          }
          alt="Download"
        />
      </div>
    </div>
  );
}
export default DownloadOurApp;
