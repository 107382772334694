import "./css/trigsySection.css";
function TrigsySection() {
  return (
    <div className="trigsySection">
      <span className="trigsyLogoAndText">
        <img
          src={require("../../assets/images/footer/footerLogo.svg").default}
          alt="Logo"
        />
        <p id="trigsy">Trigsy</p>
      </span>
      <p id="footerTrigsySubHeading">
        Step into our digital universe, where every voice matters and where
        people can explore, engage, and forge meaningful connections in a
        vibrant online community.
      </p>
      <span className="socialMediaIcons">
        <a
          href="https://www.youtube.com/@trigsy"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={require("../../assets/images/footer/iconYoutube.svg").default}
            alt="youtube"
          />
        </a>
        <a
          href="https://www.instagram.com/trigsyvibes/"
          id="instagramId"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={
              require("../../assets/images/footer/iconInstagram.svg").default
            }
            alt="instagram"
          />
        </a>
        <a
          href="https://www.linkedin.com/company/trigsy/"
          id="linkedInId"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={require("../../assets/images/footer/iconLinkedin.svg").default}
            alt="linkedIn"
          />
        </a>
        <a
          href="https://twitter.com/trigsyvibes"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={require("../../assets/images/footer/iconTwitter.svg").default}
            alt="twitter"
            id="twitter"
          />
        </a>
        <a href="https://tech.trigsy.com/" target="_blank" rel="noreferrer">
          <img
            src={require("../../assets/images/footer/iconMedium.svg").default}
            alt="medium"
          />
        </a>
      </span>
    </div>
  );
}
export default TrigsySection;
