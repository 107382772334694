import FooterTextHeading from "../../components/footerTextHeadings";
import IconWithLinkSubHeading from "../../components/iconWithLinkSubHeading";
import "./css/helpAndSupport.css";
function HelpAndSupport() {
  return (
    <div className="helpAndSupport">
      {" "}
      <div id="helpSupportHeading">
        <FooterTextHeading heading="Help & Support" />
      </div>
      <IconWithLinkSubHeading
        linkPath="mailto:customer-support@trigsy.com?body="
        subHeading="customer-support@trigsy.com"
        icon={require("../../assets/images/footer/footerEmail.svg").default}
      />
      {/* <IconWithSubHeading
        subHeading="01414937621,100#"
        icon={require("../../assets/images/footer/footerCall.svg").default}
      /> */}
    </div>
  );
}
export default HelpAndSupport;
