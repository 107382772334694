import React from "react";

function Logo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="41"
      fill="none"
      viewBox="0 0 31 41"
    >
      <path
        fill="#0772EB"
        fillRule="evenodd"
        d="M0 11.628l26.981-2.454L21.398 0 0 11.628z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#78B7FF"
        fillRule="evenodd"
        d="M12.316 37.997V14.502H.058l12.258 23.495z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#2878D4"
        fillRule="evenodd"
        d="M17.96 14.502l-2.499 25.5 14.761-25.5H17.96z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#4E9BED"
        fillRule="evenodd"
        d="M19.132 15.319l-3.67 24.682 14.76-25.5-11.09.818z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Logo;
