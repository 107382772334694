import "../../main/home/css/home.css";
import CloudAnimation from "./cloudAnimation";
import HomeContent from "./homeContent";
function Home() {
  return (
    <section className="section1">
      <HomeContent />
      <CloudAnimation />
      <div class="wave wave1"></div>
      <div class="wave wave2"></div>
      <div class="wave wave3"></div> 
    </section>
  );
}
export default Home;
