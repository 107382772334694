import "./css/trigsySection.css";
function TrigsySection() {
  return (
    <div className="partnerTrigsySection">
      <span className="partnerTrigsyLogoAndText">
        <img
          src={require("../../assets/images/footer/footerLogo.svg").default}
          alt="Logo"
        />
        <p id="trigsy">Trigsy</p>
      </span>
      <p id="partnerFooterTrigsySubHeading">
        Open the doors to your own thriving cloud shop and unleash limitless
        growth potential.
      </p>
      <span className="partnerSocialMediaIcons">
        <a
          href="https://www.youtube.com/@trigsy"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={require("../../assets/images/footer/iconYoutube.svg").default}
            alt="youtube"
          />
        </a>
        <a
          href="https://www.instagram.com/trigsyvibes/"
          id="partnerInstagramId"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={
              require("../../assets/images/footer/iconInstagram.svg").default
            }
            alt="instagram"
          />
        </a>
        <a
          href="https://www.linkedin.com/company/trigsy/"
          id="linkedInId"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={require("../../assets/images/footer/iconLinkedin.svg").default}
            alt="partnerLinkedIn"
          />
        </a>
        <a
          href="https://twitter.com/trigsyvibes"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={require("../../assets/images/footer/iconTwitter.svg").default}
            alt="twitter"
            id="partnerTwitter"
          />
        </a>
        <a href="https://tech.trigsy.com/" target="_blank" rel="noreferrer">
          <img
            src={require("../../assets/images/footer/iconMedium.svg").default}
            alt="medium"
          />
        </a>
      </span>
    </div>
  );
}
export default TrigsySection;
