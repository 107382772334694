import "../howItWorks/css/howItWorks.css";
function HowItWorks() {
  return (
    <div className="howItWorksFunc">
      <p id="howItWorksHeading">How It works?</p>
      <div id="howItWorksBodyImagediv">
        <img
          id="howItWorksImage"
          src={
            require("../../../assets/images/howItWorks/partner-steps.svg")
              .default
          }
          alt="howItWorks"
        />
      </div>
    </div>
  );
}
export default HowItWorks;
