import Circle from "../../../components/circle";
import VerticalDottedLine from "../../../components/verticalDottedLines";
import "../howItWorks/css/howItWorks.css";
import HowItWorksImageTexts from "./howItWorksBody";
function HowItWorks() {
  return (
    <div className="howItWorksFunc">
      <p id="howItWorksHeading">How It works?</p>
      <div className="circleWithDottedLines">
        <Circle />
        <VerticalDottedLine />
        <Circle />
      </div>
      <HowItWorksImageTexts />
    </div>
  );
}
export default HowItWorks;
