import React from "react";
import "./css/circleWithNumber.css";
const CircleWithNumber = ({ number }) => {
  return (
    <div className="circle-container">
      <div className="circle">
        <span className="number">{number}</span>
      </div>
    </div>
  );
};

export default CircleWithNumber;
