import "./css/partnerDownloadOurApp.css";
import GetItOnPlayStoreImage from "../../../assets/images/getItOnPlayStoreImage";

function DownloadOurApp() {
  return (
    <div className="downloadAppContainer">
      <div className="downloadTextContents">
        <p id="downloadAppHeading">Download Our App</p>
        <span id="downloadAppSubHeading">
          <p>
            Enhance your virtual journey, download our app from the Play Store
            or App Store and step into a world of boundless possibilities.
          </p>
        </span>
        <div className="downloadAppImages">
          <span id="downloadPlayStoreImage">
            <a
              href="https://play.google.com/store/apps/details?id=com.trigsy.partner&hl=en-IN"
              target="_blank"
              rel="noreferrer"
            >
              <GetItOnPlayStoreImage />
            </a>
          </span>
        </div>
      </div>
      <div className="downloadAppImage">
        <img
          src={
            require("../../../assets/images/downloadOurApp//download-app-partner.svg")
              .default
          }
          alt="Download"
        />
      </div>
    </div>
  );
}
export default DownloadOurApp;
