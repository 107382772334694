import "./css/flutterThanksWebiste.css";
function FlutterThanksWebiste() {
  return (
    <div className="flutterThanksWebsite">
      <div id="titleLogo">
        <img
          src={require("./css/trigsy-logo-horizontal.png")}
          alt="logo"
          height={25}
        />
      </div>
      <div id="illustration">
        <img src={require("./css/join-shop.svg").default} alt="img" />
      </div>
      <h1 id="thanksForSubmittingWebsiteHeading">
        Thank You for trusting trigsy!
      </h1>
      <p id="thanksForSubmittingWebsiteSubHeading">
        Please click on <span id="doneBtn">done</span> to go back to the app.
      </p>
      <div className="thanksSubHeading">
        <span id="noteText">Note:</span>
        <ul>
          <li>
            If the payment is successful, you can see the updated number of
            calls.
          </li>
          <li>
            If the payment is pending, please check after 30 mins, you'll
            receive a mail updating the status of payment.
          </li>
          <li>
            If the payment has failed and money is deducted, money will be
            returned to your bank account within 7 days.
          </li>
        </ul>
      </div>
    </div>
  );
}
export default FlutterThanksWebiste;
