import "./css/cloudAnimation.css";
function CloudAnimation() {
  return (
    <div id="background-wrap">
      <div class="x2">
        <div class="cloud"></div>
      </div>

      <div class="x3">
        <div class="cloud"></div>
      </div>

      <div class="x4">
        <div class="cloud"></div>
      </div>
    </div>
  );
}

export default CloudAnimation;
