import ImageWithHeadingAndSubHeading from "../../../components/imageWithHeadingAndSubHeading";

import "../about/css/about.css";
function About() {
  return (
    <div className="About">
      <p id="aboutHeading">One Stop Solution To All Your Needs</p>
      <section id="cloudShopText1">
        <ImageWithHeadingAndSubHeading
          heading="Cloud Shop"
          subHeading="Unlock a world of possibilities: Experience the convenience and variety of our Cloud Shop for all your service needs."
          imagePath={
            require("../../../assets/images/aboutApp/shop.svg").default
          }
        />
      </section>
      <section id="onlineMarkerPlace">
        <ImageWithHeadingAndSubHeading
          heading="Online Marketplace"
          subHeading="Unleash the power of our virtual space, offering a wide range of services to meet the unique needs of every individual"
          imagePath={
            require("../../../assets/images/aboutApp/settingsIcon.svg").default
          }
        />
      </section>
      <section id="verifiedProfessionals">
        <ImageWithHeadingAndSubHeading
          heading="Verified Professionals"
          subHeading="Trust in Authenticity - Connect with professionals backed by verified NID data"
          imagePath={
            require("../../../assets/images/aboutApp/peopleIcon.svg").default
          }
        />
      </section>
      <section id="realTimeSearch">
        <ImageWithHeadingAndSubHeading
          heading="Real-time Search"
          subHeading="Discover Nearby Excellence: Harness the power of location-based search to find top-notch professionals and services in your area."
          imagePath={
            require("../../../assets/images/aboutApp/locationIcon.svg").default
          }
        />
      </section>
      <section id="aboutImage">
        <img
          src={require("../../../assets/images/aboutApp/aboutApp.svg").default}
          alt="AboutImage"
        />
      </section>
    </div>
  );
}
export default About;
