import React from "react";

function Hamburger() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="52"
      height="24"
      viewBox="0 0 52 24"
    >
      <g fill="#574c4c" data-name="Group 9" transform="translate(-294 -47)">
        <rect
          width="42"
          height="4"
          data-name="Rectangle 3"
          rx="2"
          transform="translate(304 47)"
        ></rect>
        <rect
          width="42"
          height="4"
          data-name="Rectangle 5"
          rx="2"
          transform="translate(304 67)"
        ></rect>
        <rect
          width="52"
          height="4"
          data-name="Rectangle 4"
          rx="2"
          transform="translate(304 57)"
        ></rect>
      </g>
    </svg>
  );
}

export default Hamburger;
