import CircleWithNumber from "./circleWithNumber";
import "./css/headingAndSubHeadingWithCircleNumber.css";
function HeadingAndSubHeadingWithCircleNumber(props) {
  const { heading, subHeading, stepNumber, stepNumberRight } = props;
  return (
    <div className="circleNumberWithHeadingAndSubHeading">
      {stepNumber != null ? (
        <div className="circleWithNumber">
          <CircleWithNumber number={stepNumber} />
        </div>
      ) : (
        <p></p>
      )}
      <div className="downloadSteps">
        <p id="downloadStepHeading">{heading}</p>
        <p id="downloadStepSubHeading">{subHeading}</p>
      </div>
      {stepNumberRight != null ? (
        <div className="circleWithNumberRight">
          <CircleWithNumber number={stepNumberRight} />
        </div>
      ) : (
        <p></p>
      )}
    </div>
  );
}
export default HeadingAndSubHeadingWithCircleNumber;
