import { Route, Routes } from "react-router-dom";
import React from "react";
import App from "./App";
import PartnerApp from "./partners/partnerApp";
import Recharge from "./recharge/layouts/main/recharge/recharge";
import FlutterThanksWebiste from "./flutterThanksWebiste/flutterThanksWebsite";
class RouteFile extends React.Component {
  render() {
    return (
      <div>
        <Routes>
          <Route exact path="/" element={<App />} />
          <Route path="/partners" element={<PartnerApp />} />
          <Route path="/partnerRecharge" element={<Recharge />} />
          <Route
            path="/thanksForSubmitting"
            element={<FlutterThanksWebiste />}
          />
        </Routes>
      </div>
    );
  }
}
export default RouteFile;
