import HeadingAndSubHeadingWithCircleNumber from "../../../components/headingAndSubHeadingWithCircleNumber";
import "./css/downloadStepThree.css";
function DownloadStepThree() {
  return (
    <div className="downloadStepThree">
      <div id="downloadImage3">
        <img
          src={require("../../../assets/images/howItWorks/downloadStep3.svg").default}
          alt="Download_image_3"
        />
      </div>
      <div className="downloadStep3">
        <div id="enjoyTheApp">
          <HeadingAndSubHeadingWithCircleNumber
            heading="Its done enjoy the app"
            subHeading="Get most amazing app experience ,explore the app and share the app "
            stepNumber="3"
          />
        </div>
      </div>
    </div>
  );
}
export default DownloadStepThree;
