import TextField from "./textField";
import "./css/recharge.css";
import React, { useState } from "react";
import LoadingSpinner from "../../../components/LoadingSpinner/loadingSpinner";
function Recharge() {
  const [isLoading, setIsLoading] = useState(false);
  const loading = (isLoad) => {
    setIsLoading(isLoad);
  };
  return (
    <div>
      {isLoading && <LoadingSpinner />}
      <div>
        <p id="rechargeHeading">Trigsy Recharge</p>
        <TextField loading={loading} />
      </div>
    </div>
  );
}
export default Recharge;
