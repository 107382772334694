import HeadingAndSubHeadingWithCircleNumber from "../../../components/headingAndSubHeadingWithCircleNumber";
import "./css/howItWorksBody.css";
import DownloadStepThree from "./downloadStepThree";
import DownloadStepTwo from "./downloadStepTwo";
function HowItWorksImageTexts() {
  return (
    <div>
      {" "}
      <div id="downloadImage1">
        <img
          src={require("../../../assets/images/howItWorks/downloadStep1.svg").default}
          alt="Download_image_1"
        />
      </div>
      <div className="downloadStep1">
        <div id="downloadApp">
          <HeadingAndSubHeadingWithCircleNumber
            heading="Download App"
            subHeading="Download App for IOS and Android"
            stepNumber="1"
          />
        </div>
        <span id="appleImage">
          <img
            src={require("../../../assets/images/howItWorks/appleIcon.svg").default}
            alt="appleImage"
          />
        </span>
        <span>
          <img
            src={require("../../../assets/images/howItWorks/androidIcon.svg").default}
            alt="androidImage"
          />
        </span>
      </div>
      <DownloadStepTwo />
      <DownloadStepThree />
    </div>
  );
}
export default HowItWorksImageTexts;
